@import '../../../../bb_base/scss/theme-bootstrap';

#email-signup-block-coresite {
  max-width: 1024px;
  margin: 30px auto 20px;
  padding: 20px 90px 50px;
  overflow: hidden;
  position: relative;
  @include breakpoint ($small-down) {
    width: 100%;
    padding: 30px 1%;
    margin-top: 10px;
  }
  .email-signup-header-container {
    width: 600px;
    margin: 0 auto;
    display: block;
    text-align: center;
    @include breakpoint ($small-down) {
      padding: 44px 0 20px;
      width: 100%;
      border-bottom: 2px solid $color-gray;
    }
    h1 {
      font-family: $bbtext-regular;
      letter-spacing: 2px;
      font-weight: 400;
      margin-bottom: 18px;
      font-size: 3em;
      line-height: 1em;
      text-transform: uppercase;
      @include breakpoint ($small-down) {
        font-size: 1.5em;
        height: auto;
      }
    }
    .desc {
      font-size: 1.5em;
      font-weight: 400;
      margin: 0 0 40px;
      padding: 0;
      display: block;
      @include breakpoint ($small-down) {
        font-size: 1em;
      }
    }
  }
  .contact-form-wrap {
    padding: 0 110px;
    display: block;
    @include breakpoint ($small-down) {
      display: block;
      margin: 0 20px;
      padding: 0;
    }
    .contact_mandatory_fields {
      text-align: left;
      display: block;
      font-size: 14px;
      &.me-arabic {
        text-align: right;
      }
    }
  }
  .form-error {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .label-error {
    color: $color-red;
    label {
      color: $color-red;
    }
    input {
      @include input-placeholder {
        color: $color-red;
      }
    }
  }
  .form-builder-container-coresite {
    .form-type-textfield {
      float: left;
      margin: 10px 3% 10px 0;
      width: 47%;
      display: block;
      @include breakpoint ($small-down) {
        width: 100%;
        margin: 0 auto;
      }
      &.label-error {
        input,
        select {
          border-color: $color-red;
        }
        select {
          color: $color-red;
        }
      }
      input,
      select {
        width: 100%;
        height: 32px;
        line-height: 20px;
        border-radius: 0;
        border: 1px solid $color-black;
        font-family: $bbtext-regular;
        background-position: 97% 50%;
        &:focus {
          border-color: $color-pink;
        }
        @include breakpoint ($small-down) {
          width: 97%;
          margin: 0.5em 3% 0.5em 0;
        }
      }
      select {
        padding: 5px 6px;
        @include breakpoint ($small-down) {
          padding: 5px 10px;
        }
      }
      .select-color-gray {
        color: $color-gray-dark;
      }
      .select-color-black {
        color: $color-black;
      }
      option {
        padding: 0;
      }
    }
    .form-type-checkboxes {
      display: block;
      float: left;
      text-align: left;
      margin: 0;
      width: 100%;
      font-size: 14px;
      &.custom-outline {
        outline: none !important;
      }
    }
    .form-type-desc {
      display: inline-block;
      margin-top: 20px;
      p {
        margin: 0;
      }
    }
    &.me-arabic {
      .form-type-textfield {
        float: right;
        margin: 10px 0 10px 3%;
        @include breakpoint ($small-down) {
          margin: 0 auto;
        }
        &.form-item-form-PHONE-1 {
          input {
            direction: ltr;
            text-align: right;
          }
        }
        input,
        select {
          @include breakpoint ($small-down) {
            margin: 0.5em 0;
          }
        }
      }
      .form-type-checkboxes {
        float: none;
        text-align: right;
        display: flex;
        label {
          float: right;
          &::before {
            margin-left: 5px;
            margin-right: 0;
          }
        }
        input {
          right: 9999px;
          visibility: hidden;
        }
      }
      select {
        background-position: 3% 50%;
      }
      .form-submit {
        float: right;
      }
    }
  }
  .form-submit {
    float: left;
    width: 200px;
    margin-top: 20px;
    font-weight: bold;
    &:disabled {
      background: $color-gray-darker;
    }
    @include breakpoint ($small-down) {
      width: 100%;
    }
  }
}

.site-footer__country-chooser {
  display: none;
}

.device-mobile {
  .site-header {
    height: auto;
    .site-utils {
      &__links {
        display: none;
      }
    }
  }
}
